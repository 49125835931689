import { DealerSingle } from "../../components/molecules/Dealer/Dealer.d"
import { Dealer, Vehicle } from "../../global"
import { documentExists } from "../../helpers"
import { FormView, LeadType } from "./contact.d"

export enum ACTION_TYPES {
  SET_CONTACT_DEALER_MODAL_OPEN,
  SET_TACT_CONTACT_DEALER_MODAL_OPEN,
  SET_FAVORITES_CONTACT_DEALER_MODAL_OPEN,
  SET_ACTIVE_VIEW,
  SET_PREVIOUS_VIEW,
  UPDATE_FIELD,
  CLEAR_STATE,
}

export const openContactDealerModal = (
  contactDealerSelection = null,
  vehicle = null,
  leadType = null,
  contactButtonType = "ContactDealer",
  confirmContactDealerSelection = false,
) => {
  if (documentExists) {
    document.body.style.overflow = "hidden"
  }

  return {
    type: ACTION_TYPES.SET_CONTACT_DEALER_MODAL_OPEN,
    payload: {
      open: true,
      contactDealerSelection: contactDealerSelection,
      vehicle: vehicle,
      seriesName: vehicle?.series || "",
      trim: vehicle?.title || "",
      leadType: leadType,
      contactButtonType: contactButtonType,
      confirmContactDealerSelection: confirmContactDealerSelection,
    },
  }
}

export const openTactContactDealerModal = (
  contactDealerSelection: Dealer | DealerSingle = null,
) => {
  if (documentExists) {
    document.body.style.overflow = "hidden"
  }

  return {
    type: ACTION_TYPES.SET_TACT_CONTACT_DEALER_MODAL_OPEN,
    payload: {
      open: true,
      contactDealerSelection: contactDealerSelection,
      leadType: "tact",
    },
  }
}

export const openFavoritesContactDealerModal = () => {
  if (documentExists) {
    document.body.style.overflow = "hidden"
  }

  return {
    type: ACTION_TYPES.SET_FAVORITES_CONTACT_DEALER_MODAL_OPEN,
    payload: {
      open: true,
      leadType: "New Inventory Availability",
    },
  }
}

export const closeContactDealerModal = () => {
  if (documentExists) {
    document.body.style.overflow = "unset"
  }

  const contactDealerSelection: Dealer = null
  return {
    type: ACTION_TYPES.SET_CONTACT_DEALER_MODAL_OPEN,
    payload: { open: false, contactDealerSelection: contactDealerSelection },
  }
}

export const closeTactContactDealerModal = () => {
  if (documentExists) {
    document.body.style.overflow = "unset"
  }

  const contactDealerSelection: Dealer = null
  return {
    type: ACTION_TYPES.SET_TACT_CONTACT_DEALER_MODAL_OPEN,
    payload: { open: false, contactDealerSelection: contactDealerSelection },
  }
}

export const closeFavoritesContactDealerModal = () => {
  if (documentExists) {
    document.body.style.overflow = "unset"
  }

  const contactDealerSelection: Dealer = null
  return {
    type: ACTION_TYPES.SET_FAVORITES_CONTACT_DEALER_MODAL_OPEN,
    payload: { open: false, contactDealerSelection: contactDealerSelection },
  }
}

export const setActiveView = (activeView: FormView) => {
  return {
    type: ACTION_TYPES.SET_ACTIVE_VIEW,
    payload: activeView,
  }
}

export const setPreviousView = (previousView: FormView) => {
  return {
    type: ACTION_TYPES.SET_PREVIOUS_VIEW,
    payload: previousView,
  }
}

export const updateField = (key: string, value: any) => ({
  type: ACTION_TYPES.UPDATE_FIELD,
  payload: {
    key: key,
    value: value,
  },
})

export const clearState = () => {
  return {
    type: ACTION_TYPES.CLEAR_STATE,
  }
}
